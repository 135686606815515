
import { Airplane, Person, Houses } from 'react-bootstrap-icons'

const KgPerHousehold = 660  // https://www.epa.gov/energy/greenhouse-gases-equivalencies-calculator-calculations-and-references
const KgPerFlight = 2800  // https://flightcalculator.conservation.org/
const KgPerMonth = 1250 // https://www.statista.com/statistics/1049662/fossil-us-carbon-dioxide-emissions-per-person/

export default function Co2Impact({ co2Kg }: { co2Kg: number }) {
  return (<>
    <div className="vstack gap-2">
      <Houses className="mx-auto" width="2em" height="2em" />
      <div className="vstack mx-auto">
        <div className="mx-auto">{(co2Kg / KgPerHousehold).toFixed(2)} homes</div>
        <div className="mx-auto"><small className="fw-light">(average in-home energy use/month)</small></div>
      </div>

      <Person className="mx-auto" width="2em" height="2em" />
      <div className="vstack mx-auto">
        <div className="mx-auto">{(co2Kg / KgPerMonth).toFixed(2)} people</div>
        <div className="mx-auto"><small className="fw-light">(average American's consumption/month)</small></div>
      </div>

      <Airplane className="mx-auto" width="2em" height="2em" />
      <div className="vstack mx-auto">
        <div className="mx-auto">{(co2Kg / KgPerFlight).toFixed(1)} flights</div>
        <div className="mx-auto"><small className="fw-light">(from LAX to NYC)</small></div>
      </div>
    </div>
  </>)
}

