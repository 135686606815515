import { Link } from 'wouter'

import { BoxArrowUpRight }  from 'react-bootstrap-icons'

import Brand from './img/brand-light.svg'
import { BuyButton } from './BuyButton'

function FooterLink({ href, text }: { href: string, text: string }) {
  const isExternal = href.startsWith('http')

  const link = isExternal ? (
     <a className='footer-link' href={href} target={isExternal ? '_blank' : ''}>
      {text} <BoxArrowUpRight />
    </a>
  ) : (
    <Link href={href}><a className='footer-link'>{ text }</a></Link>
  )

  return <li className='pb-4 pb-md-2'>{ link }</li>
}


export default function Footer() {
  return (<div className="row d-flex text-white bg-rcdblue p-2 p-md-4">
    <div className="col-6 col-md-3 border-white order-0">
      <img src={Brand} alt="Recoolit Logo" className="img-fluid" />
      <br />
      <span>© 2023 Recoolit</span>
    </div>

    <div className="col-6 col-md-3 border-white order-2 order-md-1 pt-4 pt-md-0">
      <ul className="list-unstyled">
        <FooterLink href="https://www.recoolit.com/company/about" text="About Us" />
        <FooterLink href="https://www.recoolit.com/our-work/where-we-work" text="Where We Work" />
        <FooterLink href="https://www.recoolit.com/our-work/partner-with-us" text="Partner With Us" />
        <FooterLink href="https://www.recoolit.com/company/blog" text="Blog" />
        <FooterLink href="https://www.recoolit.com/company/careers" text="Careers" />
        <FooterLink href="https://www.recoolit.com/privacy-policy" text="Privacy Policy" />
        <FooterLink href="https://www.recoolit.com/contact-us" text="Contact Us" />
      </ul>
    </div>

    <div className="col-6 col-md-3 border-white order-3 order-md-2">
      <ul className="list-unstyled">
        <FooterLink href="https://www.recoolit.com/our-work" text="Our Work" />
        <FooterLink href="/" text="Registry" />
      </ul>
    </div>

    <div className="col-6 col-md-3 border-white order-1 order-md-3">
      <BuyButton />
    </div>
  </div>)
}
