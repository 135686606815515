
export default function LoadingSpinner({ loading }: { loading: boolean }) {
  if (loading) {
    return (<div className="spinner-border spinner-border-sm" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>)
  } else {
    return null
  }
}

