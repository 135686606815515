import { Link } from 'wouter'
import { useState } from 'react'
import { useEmailSignupForm } from 'client/src/queries'
import classNames from 'classnames'

import { usePageMeta } from './utils'
import LoadingSpinner from './LoadingSpinner'

function SignupForm() {
  const { isLoading, isError, mutate, isSuccess } = useEmailSignupForm()
  const [email, setEmail] = useState('')
  const [wasValidated, setWasValidated] = useState(false)

  if (isError) {
    return <div className='border border-danger p-2 my-3'>Sorry, there was an error. Please try again later.</div>
  }
  if (isSuccess) {
    return (
      <div className='border border-success p-2 my-3'>
        <p>Great! You're all set.</p>
        <p>You should get a confirmation in the next few minutes.</p>
        </div>
    )
  }
  return <form
      className={`${classNames({ 'was-validated': wasValidated })}  my-3`}
      noValidate
      onSubmit={(evt) => {
        evt.preventDefault();
        if (!(evt.target as HTMLFormElement).checkValidity()) {
          setWasValidated(true)
        } else {
          void mutate({ email } )
        }
      }}
  >
      <div className="row mb-3">
        <label htmlFor="email" className="col-4 col-md-3 col-form-label">
          Email address
        </label>
        <div className="col">
          <input
            className='form-control'
            id="email"
            type="email"
            placeholder="Enter email"
            onChange={ (evt) => setEmail(evt.target.value) }
            required
          />

          <div className="invalid-feedback">
            Invalid email address
          </div>

          <div className="form-text">
            We'll never share your email with anyone else.
          </div>

          <button className="btn btn-primary mt-2" type="submit" disabled={ isLoading }>
            <LoadingSpinner loading={ isLoading } />
            Submit
          </button>
        </div>
      </div>
    </form>

}

function Home() {
  usePageMeta({
    title: 'Recoolit',
    description: 'Recoolit creates and sells carbon credits that are additional, permanent, and transparent.',
  })
  return (
    <>
    <div className="col col-md-8 col-lg-6">
      <h1 className="display-5 py-2">Welcome!</h1>
      <p>
        When you <Link href="/buy">buy carbon credits</Link>, you want to ensure you're getting what you pay for.
        Recoolit credits are <strong>additional, permanent, and transparent</strong>.
      </p>
      <p>
        As part of our commitment to transparency, we've made our <Link href="/registry"><a>registry</a></Link> public, so there's no question of double-selling.
        And when you buy our credits, you can log in to your <Link href="/dashboard"><a>dashboard</a></Link> to view a detailed breakdown of the emissions you're preventing.
      </p>
      <p>
        Over the coming months, we'll be adding additional information and features here, so stay tuned!
      </p>
      <SignupForm/>
      <p>
        If you have any questions, you can read more on our <a href="https://recoolit.com">website</a> or <a href="mailto:support@recoolit.com">send us an email</a>.
      </p>
    </div>
    </>
  )
}

export default Home
