import { ReactNode } from 'react'

import client from 'client'
import type { UsePurchaseT, UsePurchaseGraphT } from 'client/src/queries'

import bubbles from './img/bubbles.png'
import recover from './img/recover.svg'
import store from './img/store.svg'
import destroy from './img/destroy.svg'

import { usePageMeta } from './utils'
import PurchaseAmount from './PurchaseAmount'
import SharePurchase from './SharePurchase'
import Co2Impact from './Co2Impact'

function Loading() {
  return (
    <p className="f3 pb-2">Loading your receipt data...</p>
  )
}

function NotFound() {
  return (
    <>
      <p className="f3 pb-2">Your receipt was not found.</p>
      <p className="f3 pb-2">If you believe this is an error, please <a href="mailto:support@recoolit.com">contact support</a> and include a link to this page, as well as information about your purchase.</p>
    </>
  )
}

type Event = UsePurchaseGraphT['events'][string][number]
type Node = UsePurchaseGraphT['paths'][number]['nodes'][number]
type Edge = UsePurchaseGraphT['paths'][number]['edges'][number]

type PurchaseDetailsP = {
  purchase: UsePurchaseT['purchase'],
}

type EventDetailsP = {
  event: Event,
  node: Node,
  in?: Edge,
  out?: Edge,
  sale: Edge,
}

function EventTime({ ts, type }: { ts: Date, type: Event['type'] }) {
  let icon = store
  if (type === 'recovery') icon = recover
  if (type === 'destruction') icon = destroy

  return (
    <p className="d-flex align-items-center">
      <img src={ icon } style={{ height: '1rem' }} className="me-2" />
      <span className="text-muted">{ts.toDateString()}</span>
    </p>
  )
}

function EventDetails({ event, out, sale }: EventDetailsP) {
  let desc: ReactNode

  if (event.type === 'recovery') {
    desc = <p>
      { event.event.technicianName } recovered { out!.weight } grams of {
      out!.gas } into cylinder { event.event.eqStickerCode! }. { sale.weight } of that is yours.
    </p>
  } else if (event.type === 'destruction') {
    if (event.event.eventType === 'destroyed') {
      desc = <p>
        Cylinder { event.event.eqStickerCode } was hooked up to a feeder and the gas was destroyed.
      </p>
    } else {
      desc = <p>
        A destruction-related event happened to cylinder { event.event.eqStickerCode }.
      </p>
    }
  } else if (event.type === 'move') {
    desc = <p>Cylinder { event.event.eqStickerCode } was moved to { event.event.destName }.</p>
  } else if (event.type === 'transfer') {
    desc = <p>Gas was transferred from { event.event.originStickerCode } to { event.event.destStickerCode }.</p>
  } else {
    desc = <p>Something happened to this cylinder</p>
  }

  return <>
    <EventTime ts={event.ts!} type={ event.type } />
    { desc }
  </>
}

type PurchaseAllocationP = {
  path: UsePurchaseGraphT['paths'][number],
  idx: number,
  events: UsePurchaseGraphT['events'],
}


function PurchaseAllocation({ path, idx, events }: PurchaseAllocationP) {
  const recoveryN = path.nodes.find(n => n.type === 'recovery')!

  const gasEdges = path.edges
    .filter(e => e.type !== 'sale')
    .sort((e1, e2) => e1.date.valueOf() - e2.date.valueOf())
  const nodeIds: Array<Node['id']> = [gasEdges[0].source, ...gasEdges.map(e => e.dest)]
  const nodes = nodeIds.map(nodeId => path.nodes.find(n => n.id === nodeId)!)

  const displayedEvents: Array<EventDetailsP> = []

  for (const node of nodes) {
    const inEdge = gasEdges.find(e => e.dest === node.id)
    const outEdge = gasEdges.find(e => e.source === node.id)

    const prevNode = inEdge && path.nodes.find(n => n.id === inEdge.source)
    //const nextNode = outEdge && path.nodes.find(n => n.id === outEdge.dest)

    const saleEdge = path.edges.find(e => e.type === 'sale' && e.source === node.id)!

    for (const event of (events[node.id] || [])) {
      let relevant = false

      // recoveries always listed
      if (event.type === 'recovery') {
        relevant = true;

      // for now, only render the actual destruction events
      } else if (event.type === 'destruction') {
        if (event.event.eventType === 'destroyed') relevant = true;

      // only transfers into the current node are displayed
      } else if (event.type === 'transfer') {
        if (!prevNode) continue

        if (event.event.originEquipmentId === prevNode.equipmentId && event.event.destEquipmentId === node.equipmentId)
          relevant = true;

      // moves are displayed if they're between the incoming and outgoing transfer
      } else if (event.type === 'move') {
        // exclude if they happened before gas was transferred into current node
        if (!inEdge || inEdge.date > event.ts!) continue

        // exclude if they happened after gas was transferred out
        if (!outEdge || outEdge.date < event.ts!) continue

        relevant = true
      }

      if (relevant) {
        displayedEvents.push({ event, node, in: inEdge, out: outEdge, sale: saleEdge })
      }
    }
  }

  return <div className="card">
    <div className="card-body">
      <div className="fs-4">Recovery {idx + 1}</div>
      <p>{ (new Date(recoveryN.minDate)).toDateString() }</p>

      { displayedEvents.map(disp => <EventDetails {...disp} key={disp.event.event.id} />) }
    </div>
  </div>
}

function PurchaseAllocations({ purchaseId }: { purchaseId: string }) {
  const { isLoading, error, data } = client.usePurchaseGraph(purchaseId)
  if (isLoading)
    return <div className="spinner-border text-primary ms-3" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>

  if (error)
    return <span className="text-error">Error loading purchase allocation</span>

  const paths = data!.paths
  const byGas = Object.entries(data!.byGasType).map(([gas,weight]) =>
    `${weight} grams of ${gas}`
  )

  return <div className="vstack gap-2">
    <p>
      Your purchase was allocated from the destruction of {byGas.map((gs, idx) => {
        let prefix: string

        // first item
        if (idx === 0) prefix = ''

        // last item
        else if (idx === (byGas.length - 1)) {
          // there were more than two
          if (byGas.length > 2) prefix = ', and '
          else prefix = ' and '

        // intermediate item
        } else prefix = ', '

        return <>{prefix}<b key={idx}>{gs}</b></>
      })}.
    </p>
    { paths.map((path, idx) => <PurchaseAllocation path={path} events={data!.events} idx={idx} key={idx} />) }
  </div>
}

function PurchaseDetails({ purchase }: PurchaseDetailsP) {
  const name = purchase.userName ? purchase.userName.split(' ').at(0) : 'You'
  const statusIcon = purchase.inGraph ? '✅' : '🟡'
  const statusText = purchase.inGraph ? 'Delivered' : 'In Progress'

  return (
    <>
      <div className="col-lg-6 vstack gap-3">
        <span className="display-5 py-2 text-gradient me-auto">Thank You!</span>

        <div className="card">
          <div className="card-body gradient-stripe">
            <p>
              💸 {name} prevented the emissions of <b><PurchaseAmount co2eKg={ purchase.co2eKg } /></b> of CO2e.
              This is equivalent to:
            </p>

            <Co2Impact co2Kg={ purchase.co2eKg } />

            <ul className="list-unstyled mt-2">
              <li>Purchase Date: <b>{ new Date(purchase.purchasedAt).toDateString() }</b></li>
              <li>Serial Number: <b>{ purchase.id }</b></li>
              <li>Status: {statusIcon} {statusText}</li>
              <li>
                Anyone can view this page, so feel free to share it
                on <SharePurchase purchase={purchase} service="LinkedIn" /> or <SharePurchase purchase={purchase} service="Twitter" />!
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="col-lg-4 ps-md-4">
        { purchase.inGraph &&
        <div className="vstack gap-3">
          <span className="py-2 fs-4">Here's how we did it</span>
          <div
            style={{
              width: '25em',
              height: '9em',
              backgroundImage: `url(${bubbles})`,
              backgroundPosition: 'top',
              backgroundSize: '100%',
              backgroundRepeat: 'no-repeat',
            }}
            className="row justify-content-center align-items-end"
          >
            <div className="col-4 p-0 d-flex justify-content-start ps-4">
              Recovery</div>
            <div className="col-4 p-0 d-flex justify-content-center">
              Storage</div>
            <div className="col-4 p-0 d-flex justify-content-end pe-2">
              Destruction</div>
          </div>
          <PurchaseAllocations purchaseId={purchase.id} />
        </div>
        }
      </div>
    </>
  );
}

function Receipt({ purchaseId }: { purchaseId: string}) {

  usePageMeta({
      title: 'Credit Details - Recoolit',
      description: 'Thank you for your purchase! Here\'s a detailed breakdown of how we prevented emissions on your behalf.',
    })
  const { isLoading, data } = client.usePurchase(purchaseId)
  const purchase = data?.purchase

  if (isLoading) {
    return <Loading />
  } else if (!purchase) {
    return <NotFound />
  } else {
    return <PurchaseDetails purchase={purchase} />
  }
}

export default Receipt;
