import { useState, useEffect } from 'react'

import classNames from 'classnames'

import Nav from 'react-bootstrap/Nav';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form'

import { useEnterpriseSignupForm, useRegistryPurchase, useSession } from 'client/src/queries';

import { useSearchParam } from './lib/searchState'
import { usePageMeta } from './utils'
import LoadingSpinner from './LoadingSpinner'
import Co2Impact from './Co2Impact'

const limits = {
  consumer: { min: 15, step: 15, max: 1_000, default: 95 },
  business: { min: 15, step: 15, max: 20_000, default: 300 },
  enterprise: { min: 0, step: 0, max: 0, default: 0 },
} as const

type Plans = keyof typeof limits

const centsPerKg = 7.5

function EnterpriseContact() {
  const { isLoading, isError, mutate, isSuccess } = useEnterpriseSignupForm()

  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [wasValidated, setWasValidated] = useState(false)

  if (isError) {
    return <><p></p><div className='card card-danger'><div className="card-body">
      Sorry, there was an error. Please try again later.
    </div></div></>
  }

  if (isSuccess) {
    return <><p></p><div className='card card-success'><div className="card-body">
      Thanks! We will be in touch shortly!
    </div></div></>
  }

  return <form
    className={ classNames({ 'was-validated': wasValidated }) }
    noValidate
    onSubmit={(evt) => {
      evt.preventDefault();
      if (!(evt.target as HTMLFormElement).checkValidity()) {
        setWasValidated(true)
      } else {
        void mutate({ email, message } )
      }
    }}
  >
    <p>We would love to partner with you. Please let us know a bit about you, and we'll get in touch!</p>
    <div className="card"><div className="card-body">
      <div className="vstack gap-3">
        <div>
          <label htmlFor="entEmail" className="form-label">Your Email</label>
          <input type="email" className="form-control" id="entEmail" placeholder="name@example.com"
            required value={email} onChange={(e) => setEmail(e.target.value)}
          />
          <div className="invalid-feedback">
            Invalid email address
          </div>
        </div>

        <div>
          <label htmlFor="exampleFormControlTextarea1" className="form-label">Message</label>
          <textarea className="form-control" id="exampleFormControlTextarea1" rows={3}
            value={message} onChange={(e) => setMessage(e.target.value)}
          ></textarea>
        </div>

        <button className="btn btn-primary" type="submit" disabled={ isLoading }>
          <LoadingSpinner loading={ isLoading } />
          Get In Touch
        </button>
      </div>

    </div></div>
  </form>
}

type PurchaseablePlans = Exclude<Plans, 'enterprise'>
type PurchaseFormProps = {
  plan: PurchaseablePlans,
  amount: number,
  updateAmount: (e: React.ChangeEvent<HTMLInputElement>) => void,
  co2Kg: number,
}

function PurchaseForm({ plan, amount, updateAmount, co2Kg }: PurchaseFormProps) {
  const { data: session } = useSession()

  const { isLoading, isError, mutate, isSuccess, data } = useRegistryPurchase()
  const [wasValidated, setWasValidated] = useState(false)

  const [email, setEmail] = useState('')
  const [monthly, setMonthly] = useState(true)

  // default to using the email of the logged-in user
  useEffect(() => {
    if (session?.user?.email && email === '')
      setEmail(session.user.email)
  }, [session])

  // allows us to know where to redirect after purchase or error
  const sourceUrl = window.location.toString()

  // how do we display the purchase amount? kg or tonnes?
  let units = 'kg'
  let round = 0
  let co2 = co2Kg
  if (co2Kg > 6000) {
    units = 'tonnes'
    round = 1
    co2 = co2Kg / 1000
  }

  if (isError) {
    return <><p></p><div className='card card-danger'><div className="card-body">
      Sorry, there was an error. Please try again later.
    </div></div></>
  }

  if (isSuccess) {
    window.location.assign(data.checkoutUrl)
  }

  return (<form
    className={ classNames({ 'was-validated': wasValidated }) }
    noValidate
    onSubmit={(evt) => {
      evt.preventDefault();
      if (!(evt.target as HTMLFormElement).checkValidity()) {
        setWasValidated(true)
      } else {
        void mutate({ plan, amount, email, monthly, sourceUrl } )
      }
    }}
  >
    <p>
      Your purchase funds further capture and destruction of harmful refrigerant
      emissions. <a href='https://www.recoolit.com/our-work' target='_blank'>Learn more</a>.
    </p>
    <div className="card">
      <div className="card-body">
        <div className="vstack gap-2">
          <div className="hstack">
            <label htmlFor="priceText" className="form-label">
              { monthly ? 'Monthly Budget' : 'One-time payment' }
            </label>

            <div className="input-group mb-3 ms-auto" style={{ width: 'fit-content', justifyContent: 'flex-end' }}>
              <span className="input-group-text">$</span>
              <input
                style={{ width: '6em', maxWidth: '100px' }}
                type="number"
                min={ limits[plan]['min'] }
                max={ limits[plan]['max'] }
                step={ limits[plan]['step'] }
                className="form-control"
                aria-label="Amount in dollars per month"
                value={amount}
                onChange={updateAmount}
              />
              <span className="input-group-text" hidden={!monthly}>/mo</span>
              <Form.Control.Feedback type="invalid" style={{textAlign: 'right'}}>
                Please enter a multiple of ${limits[plan]['step']} or use the slider below.
              </Form.Control.Feedback>
            </div>
          </div>

          <div>
            <input
              type="range" className="amount-range" id="amountRange"
              min={ limits[plan]['min'] }
              max={ limits[plan]['max'] }
              step={ limits[plan]['step'] }
              value={amount} onChange={updateAmount}
              style={{ width: '100%' }}
            />
          </div>

          <div className="hstack">
            <div>{ monthly ? 'Monthly' : null } Tonnage:</div>
            <div className="ms-auto">
              { co2.toFixed(round) } {units} of CO2e
            </div>
          </div>

          <FloatingLabel controlId="purchaseEmail" label="Email Address" className="mt-4">
            <Form.Control type="email" placeholder="name@example.com" required
              value={email}
              onChange={(e) => setEmail(e.target.value)}

            />
            <Form.Control.Feedback type="invalid">
              Invalid email address
            </Form.Control.Feedback>
          </FloatingLabel>

          <button className="btn btn-primary" type="submit" disabled={ isLoading }>
            <LoadingSpinner loading={ isLoading } />
            Continue with ${amount}{monthly ? ' per month' : null}
          </button>

          <div className="form-check form-switch form-check-reverse">
            <input className="form-check-input" type="checkbox" id="toggleMonthly"
              checked={!monthly} onChange={() => setMonthly(!monthly)}
            />
            <label className="form-check-label fw-light" htmlFor="toggleMonthly">
              One-time purchase
            </label>
          </div>
        </div>
      </div>
    </div>
  </form>)
}

function Buy() {
  const [rawPlan, setPlan] = useSearchParam<Plans>('plan', 'business')
  const plan = Object.keys(limits).includes(rawPlan) ? rawPlan : 'business'

  const defaultAmount = limits[plan]['default'] as number
  const [amount, setAmount] = useState(defaultAmount)

  usePageMeta({
    title: 'Buy credits - Recoolit',
    description: 'High quality carbon credits from refrigerant destruction. Available for businesses or individual consumers, as a recurring subscription or a one-time purchase.',
  })

  const updateAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newVal = e.target.valueAsNumber
    if (isNaN(newVal)) return
    setAmount(newVal)
  }

  const updatePlan = (plan: Plans) => {
    setPlan(plan)
    setAmount(limits[plan]['default'] as number)
  }

  // go from amount of money to kg of CO2e
  const co2Kg = (amount * 100) / centsPerKg

  return (
    <>
      <Nav variant="pills" activeKey={plan} onSelect={(plan) => updatePlan(plan as Plans)} className="mb-2">
        <Nav.Item><Nav.Link eventKey="business">Business</Nav.Link></Nav.Item>
        <Nav.Item><Nav.Link eventKey="consumer">Consumer</Nav.Link></Nav.Item>
        <Nav.Item><Nav.Link eventKey="enterprise">Enterprise</Nav.Link></Nav.Item>
      </Nav>

      <div className="col-12 col-md-7">
        { plan === 'enterprise' ? <EnterpriseContact /> :
          <PurchaseForm plan={plan} amount={amount} updateAmount={updateAmount} co2Kg={co2Kg} />
        }
      </div>

      { plan === 'enterprise' ? null : (
      <div className="col-12 col-md-5 d-none d-md-block">
        <p className="ps-lg-2">This is equivalent to:</p>
        <Co2Impact co2Kg={co2Kg} />
      </div>
      )}
    </>
  )
}

export default Buy
