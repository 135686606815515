import { Link } from 'wouter'

import { useSession, useRegistry } from '../../client/src/queries'
import type { UseRegistryT } from '../../client/src/queries'

import { usePageMeta } from './utils'
import LoadingSpinner from './LoadingSpinner'
import PurchaseAmount from './PurchaseAmount'
import { ProfileImage } from './Avatar'

function RegistryUUID({lastSix}: {lastSix: string}) {
  return <span>
    <span style={{filter: 'blur(6px)'}}>{'x'.repeat(30)}</span>
    {lastSix}
    </span>
}

type RegistryEntry = UseRegistryT['purchases'][0]

function PurchaseCard({ entry }: {entry: RegistryEntry}) {
  const statusIcon = entry.status === 'Delivered' ? '✅' : '🟡'

  return <div className="card">
    <div className="card-body pb-4 pt-4 border-bottom">
      <div className="row">
        <div className="col-3 col-lg-2 col-xl-1 my-2 px-4">
          <ProfileImage avatarDetails={entry.avatarDetails}/>
        </div>
        <div className="col-5 col-lg-2 col-xl-3 my-2 px-4">
          <div className="row lead">{ entry.displayName || 'Anonymous' }</div>
          <div className="row"></div>
        </div>
        <div className="col-4 col-lg-2 col-xl-2 my-2 px-4">
          <div className="row">{statusIcon} { entry.status }</div>
          <div className="row"><strong className="p-0"><PurchaseAmount co2eKg={entry.co2e} /></strong></div>
        </div>
        <div className="col col-sm-12 col-lg-6 my-2 px-4">
          <div className="row">Serial Number</div>
          <div className="row"><b><RegistryUUID lastSix={entry.truncatedId} /></b></div>
        </div>
      </div>
    </div>
  </div>
}

function RegistryList({ registryPurchases } : {registryPurchases: UseRegistryT['purchases']}) {
  return <div className='col'>
      <div className='vstack gap-3'>
        {registryPurchases.map(p => <PurchaseCard entry={p} key={p.truncatedId} />)}
      </div>
      </div>

}

function Registry() {
  usePageMeta({
    title: 'Registry - Recoolit',
    description: 'Recoolit is committed to transparency in carbon markets. Our public  registry ensures that when you buy carbon credits, you know exactly where your money is going.',
  })

  const { isLoading, data: registry} = useRegistry()
  const { data: session } = useSession()

  const loggedIn = !!session?.user

  let body
  let totalDisplay
  if (isLoading) {
    body = <LoadingSpinner loading={true} />
    totalDisplay = <LoadingSpinner loading={true} />
  } else if (!registry) {
    body = 'No registry found.'
    totalDisplay = 'Total not available.'
  } else {
    body = <RegistryList registryPurchases={registry.purchases.sort(
      (p1, p2) => p2.date.valueOf() - p1.date.valueOf()
    ) } />
    const totalCO2e = registry.totalCO2e / (1000 * 1000) // grams to tonnes
    totalDisplay = <span className='text-gradient'>{ totalCO2e.toFixed(2) }</span>
  }

  return (
    <>
    <div>
      <h1 className="display-5">Registry</h1>
      <div className="row mt-4 mb-lg-5">
        <div className='col-12 col-md-8 col-lg-6'>
          <p><b>Recoolit is committed to transparency in carbon markets.</b></p>
          <p>We track every molecule of emissions we prevent, and our in-house registry ensures that when you buy our credits you know exactly what you're getting.</p>
          { loggedIn ? null : <p>
            Want your name on this list? <Link className="text-gradient" href="/buy">Buy credits now</Link>.
            <br />
            Already purchased? <Link className="link link-secondary" href="/dashboard">View your dashboard</Link>.
          </p> }
        </div>
        <div className='col-12 col-md-4 d-flex align-items-center justify-content-center'>
          <div className="d-flex flex-column align-items-center">
            <h1>{ totalDisplay }</h1>
            <p>total tonnes delivered</p>
          </div>
        </div>
      </div>
    </div>
    <div>{ body } </div>
    </>
  )
}

export default Registry
