import { PropsWithChildren } from 'react'

import { ErrorBoundary } from '@sentry/react'

type ErrorFallbackP = {
  error: Error,
  componentStack: string,
  reset: () => void,
}
function ErrorFallback({ error, componentStack: _cs, reset }: ErrorFallbackP) {
  return (
    <div className="card">
      <div className="card-header">Something Went Wrong</div>
      <div className="card-body">
        <h5 className="card-title">We encountered an unexpected error:</h5>
        <pre className="bg-light border border-danger p-2">{ error.message }</pre>
        <a href="/" className="btn btn-primary" onClick={ () => { reset(); return true; } }>Go Home</a>
      </div>
    </div>
  )
}

function Errors(props: PropsWithChildren) {
  return (
    <ErrorBoundary
      fallback={ ({ error, componentStack, resetError}) => (
        <ErrorFallback error={error} componentStack={componentStack} reset={resetError} />
      )}
    >
      { props.children }
    </ErrorBoundary>
  )
}

export default Errors
