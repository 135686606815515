import { useEffect } from 'react'

type PageMeta = {
  title: string,
  description: string,
}

function usePageMeta(meta: PageMeta) {
  useEffect(() => setPageMeta(meta))
}

function setPageMeta({title, description}: PageMeta) {
  document.title = title
  const ogTitle = document.querySelector('meta[property="og:title"]')
  if (ogTitle) ogTitle.setAttribute('content', 'Buy credits - Recoolit')

  const descriptionEl = document.querySelector('meta[name="description"]')
  if (descriptionEl) descriptionEl.setAttribute('content', description);
  const ogDescription = document.querySelector('meta[property="og:description"]')
  if (ogDescription) ogDescription.setAttribute('content', description)
}

export { usePageMeta }
