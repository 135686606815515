import type { MouseEvent } from 'react'

import type { UsePurchaseT } from 'client/src/queries'
import { purchaseAmountString } from './PurchaseAmount'

type Purchase = UsePurchaseT['purchase']

function sharePopup(evt: MouseEvent, url: string) {
  evt.preventDefault()

  window.open(
    url,
    'newwindow',
    'popup=1,width=500,height=500',
  )
}

const Twitter = (purchase: Purchase) => {
  const amount = purchaseAmountString(purchase.co2eKg)
  return `https://twitter.com/intent/tweet?url=https://registry.recoolit.com/purchases/${purchase.id}&text=I just prevented ${amount} tons of CO2 emissions with @Recoolit! Check it out.`
}

const LinkedIn = (purchase: Purchase) =>
  `https://www.linkedin.com/sharing/share-offsite/?url=https://registry.recoolit.com/purchases/${purchase.id}`

const Services = { Twitter, LinkedIn } as const

function SharePurchase({ purchase, service }: { purchase: Purchase, service: keyof typeof Services }) {
  const url = Services[service](purchase)

  return <a href={url} onClick={(evt) => sharePopup(evt, url)}>{service}</a>
}

export default SharePurchase
export {
  Services,
  sharePopup,
}
