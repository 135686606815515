import { Route, Switch, useLocation } from 'wouter'

import Receipt from './Receipt'
import Login from './Login'
import Logout from './Logout'
import Dashboard from './Dashboard'
import Congratulations from './Congratulations'
import Registry from './Registry'
import Home from './Home'
import Buy from './Buy'

export default function Routes() {
  const [location] = useLocation()
  return (<Switch>
    <Route path='/' component={Home} />
    <Route path='/registry' component={Registry} />

    <Route path="/purchases/:purchaseId">
      {params => <Receipt purchaseId={params.purchaseId!} />}
    </Route>

    <Route path='/login' component={Login} />
    <Route path='/logout' component={Logout} />

    <Route path='/dashboard' component={Dashboard} />
    <Route path='/buy' component={Buy} />
    <Route path='/congratulations' component={Congratulations} />

    <Route>
      <h2>Page not found ({location})</h2>
    </Route>
  </Switch>)
}
