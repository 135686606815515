import './App.scss'

import * as _bootstrap from 'bootstrap'

import { queryClient, QueryClientProvider } from 'client'

import Header from './Header'
import Routes from './Routes'
import Footer from './Footer'
import Errors from './Errors'
import SetNameBanner from './SetNameBanner'


function App() {

  return (
    <QueryClientProvider client={queryClient}>
      <Errors>
        <Header />

        <main className="container">
          <div className="row px-md-2 py-2 py-md-3">
            <SetNameBanner />
          </div>

          <div className="row px-md-2 py-2 py-md-3">
            <Routes />
          </div>
        </main>

        <Footer />
      </Errors>
    </QueryClientProvider>
  )
}

export default App
