import Row from 'react-bootstrap/Row'
import { Link } from 'wouter'

function Congratulations() {
  return (
    <div
      style={ {
        background: 'linear-gradient(to bottom left, #6CC0E7 9.51%, #E73C95 92.39%)',
        'borderRadius': '6px',
      }}
    >
      <Row
        className="d-flex justify-content-center p-5" >
      <div className='col col-lg-8 my-3'
        style={ {
        background: 'white' ,
        'boxShadow': '0px 4px 10px rgba(65, 114, 189, 0.1)',
        'borderRadius': '6px',
        }}
      >
        <h1 className="display-5 py-2" >
        👏
          <span style={ {
            background: 'linear-gradient(246.37deg, #6CC0E7 9.51%, #E73C95 92.39%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            backgroundClip: 'text',
            fontWeight: '700',
          }}>
            Congratulations!
          </span>
        </h1>
        <p>Thanks for your purchase of carbon credits from Recoolit. Together with your support, we're able to continue our mission of collecting and destroying the worst greenhouse gases that exist.</p>
        <p><strong>You rock!</strong></p>
        <p>We just sent you an email with more details about your purchase, including a link to view detailed data about the emissions reductions you're supporting. Your purchase should show up on your <Link href='dashboard'><a>dashboard</a></Link> any moment.</p>
        <p>If you have any questions, feel free to <a href='mailto:info@recoolit.com'>reach out by email</a>.</p>
      </div>
      </Row>
    </div>
  )
}

export default Congratulations
