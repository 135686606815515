import { useState } from 'react'
import client from 'client'
import Alert from 'react-bootstrap/Alert'
import { ProfileName } from './Dashboard'

const Thanks = ({onClose}: {onClose: () => void}) => {
  return (
    <Alert variant="success" dismissible onClose={onClose}>
      <p> Thanks! </p>
    </Alert>
  )
}

const SetNameBanner = () => {
  const { data: session } = client.useSession()
  const [isClosed, setIsClosed] = useState(
    localStorage.getItem('isSetNameBannerClosed') === 'true'
  );
  const [isThanks, setIsThanks] = useState(false);

  const rememberClose = () => {
    setIsClosed(true);
    localStorage.setItem('isSetNameBannerClosed', 'true');
  };
  if (isThanks) {
    return <Thanks onClose={() => setIsThanks(false)} />
  }

  const hasUser = !!(session && session.user)
  const alreadySet = (session && session.user && session.user.publicName)
  if (isClosed || !hasUser || alreadySet ) {
    return null;
  }


  return (
  <Alert variant = "info" onClose={ rememberClose } dismissible>
    <p>
      <strong>Hi {session.user!.name}!</strong>
    </p>
    <p>
      It looks like you haven't set a public display name yet. We'd love for your name to show up on our registry. You can set it now, or at any time from your dashboard.
    </p>
    <ProfileName user={session.user} onSuccess={() => setIsThanks(true)}/>
  </Alert>
  );
};

export default SetNameBanner;
