// based on tree-shaking init described here:
// https://docs.sentry.io/platforms/javascript/guides/react/configuration/tree-shaking/
// goal is to minimize bundle size

import {
  BrowserClient,
  Breadcrumbs,
  Dedupe,
  defaultStackParser,
  getCurrentHub,
  GlobalHandlers,
  makeFetchTransport,
  LinkedErrors,
} from '@sentry/browser';

let client: BrowserClient

function initSentry() {
  client = new BrowserClient({
    dsn: 'https://3fc4bc0b7843444bbb225a2dce92084b@o4504499082625024.ingest.sentry.io/4504726973382656',
    environment: import.meta.env.DEV ? 'dev' : 'production',
    release: (import.meta.env.VITE_SENTRY_RELEASE as string || 'default'),

    tunnel: '/api/health/report',

    transport: makeFetchTransport,
    stackParser: defaultStackParser,

    // Only the integrations listed here will be used
    integrations: [
      new Breadcrumbs(),
      new GlobalHandlers(),
      new LinkedErrors(),
      new Dedupe(),
    ],
  })

  getCurrentHub().bindClient(client)
}

export {
  initSentry,
  client,
}
