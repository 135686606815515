import React, { PropsWithChildren, useState, useEffect } from 'react'
import { Redirect, Link } from 'wouter'

import client from 'client'
import type { UsePurchasesT, UseSessionT } from 'client/src/queries'

import { usePageMeta } from './utils'
import { ProfileImageForUser } from './Avatar'
import LoadingSpinner from './LoadingSpinner'
import PurchaseAmount from './PurchaseAmount'
import { Services, sharePopup } from './SharePurchase'

import Dropdown from 'react-bootstrap/Dropdown'

type UserT = UseSessionT['user']
type Purchase = UsePurchasesT['purchases'][number]

function Spinner() {
  return (
    <div className="spinner-border spinner-border-sm" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  )
}

function HelloThanks({ user }: { user: UserT }) {
  return <>
    <div className="col-12">
      <h1 className="display-5 py-2">
        Hello,
        {' '}
        { user ? user.name.split(' ')[0] : <Spinner /> }
      </h1>

      <p>
        👏 Thanks for supporting Recoolit.
      </p>
    </div>

    <div className="col-12 col-md-10 mx-auto border-bottom my-2" />
  </>
}

type ShareDropdownToggleP = PropsWithChildren & { onClick: (evt: unknown) => void }
const ShareDropdownToggle = React.forwardRef<HTMLAnchorElement, ShareDropdownToggleP>(
  ({ children, onClick }, ref) => (
    <a
      className="link-dark"
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      &#x25bc;
    </a>
  )
);

function MiniReceipt({ purchase }: { purchase: Purchase }) {
  return <div className="card">
    <div className="card-body pb-0 border-bottom">
      <p className="lead">{ purchase.purchasedAt.toDateString() }</p>
      <p><PurchaseAmount co2eKg={ purchase.co2eKg } /></p>
    </div>
    <div className="hstack gap-2 m-2">
      <Link to={ `/purchases/${purchase.id}` }>
        <a className="ms-auto link-dark">Details</a>
      </Link>

      <Dropdown>
        <Dropdown.Toggle as={ShareDropdownToggle} id="share-dropdown">Share</Dropdown.Toggle>

        <Dropdown.Menu>
          {Object.entries(Services).map(([key, val]) =>
            <Dropdown.Item
              href={ val(purchase) }
              onClick={ (evt) => sharePopup(evt, val(purchase)) }
              key={key}
            >{key}</Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>

    </div>
  </div>
}

function Purchases({ loading, purchases }: { loading: boolean, purchases: Array<Purchase> }) {
  let purchaseSum = <p>You have not yet purchased any carbon credits</p>
  if (loading) {
    purchaseSum = <LoadingSpinner loading={true} />
  } else if (purchases.length) {
    const co2sum = purchases.map(p => p.co2eKg).reduce((tot, cur) => tot + cur, 0)
    purchaseSum = <p>
      You have {purchases.length} purchases totaling <PurchaseAmount co2eKg={ co2sum } /> CO2e
    </p>
  }
  return <div className="col">
    <p className="py-2 fs-5">Your purchases:</p>

    { purchaseSum }

    <div className="vstack gap-1">
      {purchases
        .sort((p1, p2) => p2.purchasedAt.valueOf() - p1.purchasedAt.valueOf())
        .map(p => <MiniReceipt purchase={p} key={p.id} />)
      }
    </div>
  </div>
}

function SubscriptionStatus() {
  const { isLoading, data } = client.useStripeSubscriptions()

  if (isLoading) {
    return <div className="col">
      <Spinner /> Loading subscription details...
    </div>
  }

  if (!data || !data.subscriptions.length) {
    return <div className="col">
      You are not subscribed to recurring purchases.
    </div>
  }

  const subs = data.subscriptions.sort((s1, s2) => s2.created.valueOf() - s1.created.valueOf())
  const active = subs.find(s => s.status === 'active')
  if (active) {
    const nextDate = active.nextBilling.toDateString()
    return <div className="col">
      <p>
        You have an active subscription for <PurchaseAmount co2eKg={ active.co2eKg } /> of CO2e every month.
        { active.willCancel ?
          ` Your subscription will expire on ${nextDate}` :
          ` Your next payment will be for $${active.priceCents / 100} on ${nextDate}`
        }.
      </p>

      <a
        target="_blank"
        href="https://billing.stripe.com/p/login/14k7wl1P89mBeR27ss"
        className="btn btn-primary mx-auto rec-gradient"
      >
        <b>Manage Subscription</b>
      </a>
    </div>
  }

  const sub = subs[0]
  const ended = sub.ended ? ` ${new Date(sub.ended).toDateString()}` : ''
  return <div className="col">
    <p>
      You do not currently have an active subscription.
      Your last subscription (for <PurchaseAmount co2eKg={ sub.co2eKg } /> of CO2e each month) ended {ended}.
    </p>
  </div>
}

function ProfileName({ user, onSuccess}: { user: UserT, onSuccess?: () => void }) {
  const [editing, setEditing] = useState(false)
  const [name, setName] = useState('')
  const updateUser = client.useUpdateUser()

  useEffect(() => {
    if (user)
      setName(user.publicName || user.name)
  }, [user, setName])

  if (!user)
    return <Spinner />

  if (editing)
    return <>
      <input type="text" value={ name } onChange={ (evt) => setName(evt.target.value) } />
      <button
        className="btn btn-small btn-primary"
        disabled={ updateUser.isLoading }
        onClick={() => {
          updateUser.mutate(
            { id: user.id, publicName: name },
            { onSuccess: () => {
              setEditing(false)
              if (onSuccess) { onSuccess() }
            }}
          )
        }}
      >
        { updateUser.isLoading ? <Spinner /> : null }
        Save
      </button>
    </>

  return <>
    <span className="lead text-decoration-underline">
      { user.publicName || 'Anonymous' }
    </span>
    <a style={ { textDecoration: 'none' } } href="#" onClick={ () => setEditing(true) }>🖊</a>
  </>
}


function ProfileSettings({ user }: { user: UserT }) {
  let imageExplanation
  if (user && user.publicName) {
    imageExplanation = <span>We use the <a href="https://gravatar.com">Gravatar</a> associated with your email address. Editing this will require you to create a Wordpress account if you don't already have one.</span>
  } else {
    imageExplanation = <span>We won't show your profile photo until you set a public display name.</span>
  }
  let profileImage
  if (user) {
    profileImage = <ProfileImageForUser user={ user } />
  } else { profileImage = <></> }
  return <div className="col">
    <p className="text-muted">
      All credit purchases will appear on our public registry, but you can choose how your name will appear.
      You currently appear as:
    </p>

    <div className="hstack gap-2">
      {profileImage}
      <ProfileName user={ user } />
    </div>
    <br />
    <p className="text-muted">{imageExplanation}</p>

  </div>
}

function Dashboard() {
  usePageMeta({
    title: 'Dashboard - Recoolit',
    description: 'View your purchases and subscription status.',
  })

  const { isLoading: purchaseLoading, data: purchases } = client.usePurchases(true)
  const { isLoading, data: user } = client.useSession()

  if (!isLoading && !user?.user)
    return <Redirect to='/login' />;

  return (<>
    <div className="col-md-6">
      <div className="row">
        <HelloThanks user={user?.user || null} />
      </div>
      <div className="row">
        <Purchases loading={ purchaseLoading } purchases={ purchases?.purchases || [] }/>
      </div>
    </div>

    <div className="col-md-6">
      <div className="row my-2">
        <SubscriptionStatus />
      </div>
      <div className="row">
        <ProfileSettings user={user?.user || null} />
      </div>
    </div>
  </>)
}

export { ProfileName }
export default Dashboard;
