import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { initSentry } from './sentry'

initSentry()

const root: HTMLElement = document.getElementById('registryApp')!

ReactDOM.createRoot(root).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
