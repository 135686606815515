import { PropsWithChildren } from 'react'
import { Link, useRoute } from 'wouter'
import classNames from 'classnames'

import { BoxArrowUpRight }  from 'react-bootstrap-icons'
import Navbar from 'react-bootstrap/Navbar'

import client from 'client'

import Brand from './img/brand-light.svg'
import { BuyButton } from './BuyButton'

type HeaderLinkP = PropsWithChildren & { href: string, className?: string, hidden?: boolean }

function HeaderLink({ hidden = false, className = '', href, children, ...props }: HeaderLinkP) {
  const [isActive] = useRoute(href)
  if (hidden) return null

  const classes = classNames('nav-link me-lg-4', className, { active: isActive })

  return (
    <Link href={href}><a className={ classes } {...props}>{ children }</a></Link>
  )
}

type Session = ReturnType<typeof client.useSession>['data']

function NavLinks() {
  return (
    <div className="navbar-nav ms-lg-4 me-auto">
      <HeaderLink href="/registry">Registry</HeaderLink>
      <a className="nav-link" href="https://recoolit.com/our-work" target="_blank">
        Learn More <BoxArrowUpRight/>
      </a>
    </div>
  )
}

function LoginState({ isLoading, session }: { isLoading: boolean, session: Session }) {
  if (isLoading) return (<span>...</span>)

  let content = <HeaderLink href="/login" className="mb-2 mb-lg-0">Log In</HeaderLink>

  if (session!.user) {
    content = <>
      <HeaderLink href="/dashboard">Your Dashboard</HeaderLink>
      <HeaderLink href="/logout" className="mb-2 mb-lg-0">Log Out</HeaderLink>
    </>
  }

  return (
    <div className="navbar-nav">
      { content }
      <BuyButton />
    </div>
  )
}

export default function Header() {
  const { isLoading, data: session } = client.useSession()

  return (
    <Navbar expand="lg" bg="rcdblue" sticky="top" variant="dark" className="py-3 px-4 py-lg-4 px-lg-5">
      <Link className="navbar-brand" href="/">
        <a><img src={ Brand } alt="Registry Home" /></a>
      </Link>

      <Navbar.Toggle aria-controls="main-navbar" style={ { border: 'none', boxShadow: 'none' } }/>

      <Navbar.Collapse id="main-navbar" className="mt-2 mt-lg-0">
        <NavLinks />

        <LoginState isLoading={ isLoading } session={ session } />
      </Navbar.Collapse>
    </Navbar>
  )
}
