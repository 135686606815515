import { useLocationProperty, navigate } from 'wouter/use-location';

// https://github.com/molefrog/wouter/releases/tag/v2.10.0
function useSearchParam<T extends string = string>(key: string, defaultVal: T): [T, (newVal: T) => void] {
  const val = useLocationProperty(
    () => new URLSearchParams(window.location.search).get(key)
  ) as T

  const setVal = (newVal: T) => {
    const params = new URLSearchParams(window.location.search);
    params.set(key, newVal);
    navigate('?' + params.toString());
  }

  return [val || defaultVal, setVal]

}

export { useSearchParam }
