import type { UseRegistryT, UseSessionT } from '../../client/src/queries'
import LoadingSpinner from './LoadingSpinner'
import { XCircle } from 'react-bootstrap-icons'
import { useUserAvatar } from '../../client/src/queries'

type AvatarDetails = UseRegistryT['purchases'][0]['avatarDetails']
const avatarSize = 64

type UserT = UseSessionT['user']
function ProfileImageForUser({user}: {user: NonNullable<UserT>}) {
  const {isLoading, data} = useUserAvatar(user.id)
  if (isLoading) {
    return <LoadingSpinner loading={true} />
  }
  else if (!data) {
    // error
    return <XCircle size={avatarSize} />
  }
  else {
    return <ProfileImage avatarDetails={data.avatarDetails} />
  }
}


function ProfileImage({avatarDetails}: {avatarDetails: AvatarDetails}) {
  let url
  if (!avatarDetails || avatarDetails.type != 'gravatar' ) {
    // we should never get here, but just as a fallback
    url = `https://www.gravatar.com/avatar/${Math.random()}`
  }
  else {
    url = `${avatarDetails.url}`
  }
  const src = `${url}?default=identicon&s=${avatarSize}`
  return <img
    style={ {
      width: avatarSize,
      height: avatarSize,
      borderRadius: '50%',
      objectFit: 'cover',
    } }
    src={ src } />
}


export {
  ProfileImageForUser,
  ProfileImage,
}
