import { useEffect, useState } from 'react'
import { Redirect } from 'wouter'

import client from 'client'
import classNames from 'classnames'

import Accordion from 'react-bootstrap/Accordion'

import { usePageMeta } from './utils'

function ButtonLoadingSpinner({ loading }: { loading: boolean }) {
  if (loading) {
    return (<div className="spinner-border spinner-border-sm" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>)
  } else {
    return null
  }
}

type TokenRequester = ReturnType<typeof client.useRequestToken>['requestToken']
type RequestP = {
  requestToken: TokenRequester,
  redirectUrl: URL,
}

function EmailLogin({ requestToken, redirectUrl }: RequestP) {
  const [email, setEmail] = useState('')
  const [wasValidated, setWasValidated] = useState(false)

  const requestBody = {identifier: email, method: 'email' as const, url: redirectUrl.toString()}

  return (
    <form
      className={classNames({ 'was-validated': wasValidated })}
      noValidate
      onSubmit={(evt) => {
        evt.preventDefault();
        if (!(evt.target as HTMLFormElement).checkValidity()) {
          setWasValidated(true)
        } else {
          void requestToken.mutateAsync(requestBody)
        }
      }}
      >
      <div className="row mb-3">
        <label htmlFor="email" className="col-sm-3 col-md-2 col-form-label">
          Email address
        </label>
        <div className="col">
          <input
            className='form-control'
            id="email"
            type="email"
            placeholder="Enter email"
            onChange={ (evt) => setEmail(evt.target.value) }
            required
          />

          <div className="invalid-feedback">
            Invalid email address
          </div>

          <div className="form-text">
            We'll never share your email with anyone else.
          </div>
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-sm-6 col-md-8">
          <button className="btn btn-primary" type="submit" disabled={ requestToken.isLoading }>
            <ButtonLoadingSpinner loading={ requestToken.isLoading } />
            Submit
          </button>
        </div>
      </div>
    </form>
  )
}

function CodeLogin(
  { validateToken, submit = 'Submit' }: { validateToken: ReturnType<typeof client.useValidateToken>, submit?: string }
) {
  const [code, setCode] = useState('')
  const [wasValidated, setWasValidated] = useState(false)

  return (
    <form
      className={classNames({ 'was-validated': wasValidated })}
      noValidate
      onSubmit={(evt) => {
        evt.preventDefault();
        if (!(evt.target as HTMLFormElement).checkValidity()) {
          setWasValidated(true)
        } else {
          void validateToken.mutateAsync({token: code});
        }
      }}
    >
      <div className="row mb-3">
        <label htmlFor="loginCode" className="col-sm-3 col-md-2 col-form-label">
          Login code
        </label>
        <div className="col">
          <input
            className='form-control'
            id="loginCode"
            type="text"
            placeholder="Enter login code"
            onChange={ (evt) => setCode(evt.target.value) }
            required
            minLength={6}
            maxLength={6}
          />

          <div className="invalid-feedback">
            Invalid login code
          </div>
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-sm-6 col-md-8">
          <button
            className="btn btn-primary" type="submit" disabled={ validateToken.isLoading }>
            <ButtonLoadingSpinner loading={ validateToken.isLoading } />
            { submit }
          </button>
        </div>
      </div>
    </form>
  )
}

function Login() {
  usePageMeta({
    title: 'Login - Recoolit',
    description: 'Log in to your Recoolit account to view your purchases and manage your subscription.',
    })
  const [ activeKey, setActiveKey ] = useState('email')

  const search = new URLSearchParams(window.location.search)
  const [ urlToken, setToken ] = useState(search.get('token'))
  const [ destination, _setDestination ] = useState(search.get('destination'))

  const { isLoading, isError, data, error } = client.useSession()
  const { requested, requestToken, reset } = client.useRequestToken()
  const validateToken = client.useValidateToken()

  // make sure we know about our token/possibly use it to log in
  useEffect(() => {
    if (urlToken) {
      validateToken.mutate({token: urlToken})
    }
  }, [urlToken])

  // if our user is valid, let's navigate to the home page
  if (isLoading) {
    return (<div className="spinner-border spinner-border-sm text-primary">
      <span className="visually-hidden">Loading...</span>
    </div>)
  }

  if (isError) {
    return <div>{ (error as Error).message }</div>
  }

  if (data?.user) {
    return <Redirect to={ destination || '/' } replace={true} />
  }

  // if the login has already failed, inform the user
  if (validateToken.error) {
    return (
      <div className="card border-danger">
        <div className="card-body">
          <h4 className="card-title">
            Login Failed
          </h4>

          <p className="card-text">
            Your login code was invalid.
          </p>

          <button className="btn btn-primary" onClick={() => { setToken(''); validateToken.reset() }}>
            Try again
          </button>
        </div>
      </div>
    )
  }

  // if we have a token in the store, we're trying to log in
  if (urlToken) {
    return (
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">
            Checking login token
          </h4>
          <p className="card-text">
            Please wait...
          </p>
        </div>
      </div>
    )
  }

  if (requested) {
    return (
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">
            Login Link Sent
          </h4>

          <p className="card-text">
            Click on the link you receive to finish log-in, or enter login code below:
          </p>

          <CodeLogin validateToken={validateToken} submit="Finish Login" />

          <div className="row mt-2">
            <div className="col-sm-3 col-md-2">
              Didn't receive login link?
            </div>

            <div className="col-sm-6">
              <button className="btn btn-primary" onClick={reset}>
                Try again
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  // redirect back to current page
  const redirectUrl = new URL(window.location.origin + window.location.pathname)
  if (destination) {
    redirectUrl.search = (new URLSearchParams({ destination })).toString()
  }

  return (
    <div>
    <h2>Log in</h2>
      <p>Logging in allows you to view information about your past purchases. Your account will be created automatically when you buy a credit.</p>
    <Accordion activeKey={ activeKey } onSelect={ (key) => setActiveKey(key as string) }>
      <Accordion.Item eventKey="email">
        <Accordion.Header>Use Email</Accordion.Header>
        <Accordion.Body>
          <EmailLogin requestToken={requestToken} redirectUrl={ redirectUrl }/>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="code">
        <Accordion.Header>Use login code</Accordion.Header>
        <Accordion.Body>
          <CodeLogin validateToken={validateToken} />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    </div>
  )
}

export default Login;
