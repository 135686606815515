import { useEffect, ReactNode } from 'react'

import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Spinner from 'react-bootstrap/Spinner'

import client from 'client'
import { usePageMeta } from './utils'

function Logout() {
  usePageMeta({
    title: 'Logged out - Recoolit',
    description: 'You are now logged out of your Recoolit account.',
  })
  const logout = client.useLogout()

  // are we even logged in?
  const { data } = client.useSession()
  const currentUser = data?.user

  // log out when opening this page
  useEffect(() => {
    void logout.mutateAsync()
    return logout.reset
  }, [])

  let text: ReactNode
  if (!currentUser) {
    text = <Card.Text>You are logged out</Card.Text>
  } else if (logout.isLoading) {
    text = <Card.Text>
      <Spinner variant='primary' animation='border' />You are being logged out of the app</Card.Text>
  } else {
    text = <>
      <Card.Text>Logout attempt failed</Card.Text>

      <Button variant="primary" onClick={() => void logout.mutateAsync()}>
        Try again
      </Button>
    </>
  }

  return (
    <Card>
      <Card.Body>
        <Card.Title>Log Out</Card.Title>
        { text }
      </Card.Body>
    </Card>
  )
}

export default Logout
